import React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from '../utils/parse';
import Layout from '../components/layout';
import SEO from '../components/seo';

import './product.scss';

const Product = ({ data }: any) => {
  const {
    product: {
      title, content, featuredImage, slug,
      productDetails: {
        price, productId, inStock,
      },
    },
  } = data;

  const image = getImage(featuredImage?.node?.localFile);

  return (
    <Layout showBasket categories="Shop">
      <SEO title={title} />
      <article className="article product">
        <div className="article-inner product-inner">
          <div className="product-back-link">
            <Link to="/products">Back to shopfront</Link>
          </div>
          <div className="product-wrapper">
            <div className="product-image">
              <GatsbyImage image={image} alt={featuredImage?.node.altText || ''} />
            </div>
            <div className="article-content">
              <h3 className="product-name">{title}</h3>
              <div className="product-price">
                £
                {price}
              </div>
              {parse(content)}
              <div className="product-buy-wrapper">
                <button
                  type="button"
                  className="product-buy-button snipcart-add-item"
                  disabled={!inStock}
                  data-item-id={productId}
                  data-item-price={price}
                  data-item-image={image?.images.fallback.src}
                  data-item-url={`/products/${slug}`}
                  data-item-name={title}
                >
                  {inStock ? 'Add to cart' : 'Out of stock' }

                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
};

export default Product;

export const postQuery = graphql`
query ProductById(
  $id: String!
) {
  # selecting the current post by id
  product: wpProduct(id: { eq: $id }) {
    id
    content
    title
    slug
    productDetails {
      price
      productId
      inStock
    }
    featuredImage {
      node {
        altText
        localFile {
          childImageSharp {
            gatsbyImageData(
              width: 450
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  }
}
`;
